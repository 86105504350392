import React from "react"

import { FormattedMessage, injectIntl, navigate, Link } from "gatsby-plugin-intl"

import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Herofooter from "../components/Herofooter"

import "../styles/support.scss"

import { FaFileAlt, FaQuestionCircle, FaCommentDots } from "react-icons/fa"

import ImageSupport from "../images/frontend/support-illo.png"

const SupportPage = ({ intl }) => (
  <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "SEO.LANDING.SUPPORT.TITLE" })}
        description={intl.formatMessage({ id: "SEO.LANDING.SUPPORT.DESCRIPTION" })}
        keywords={intl.formatMessage({ id: "SEO.LANDING.SUPPORT.KEYWORDS" })}
      />

    <div id="landing-support">
      <div className="tpnet-main-content">
        <tpnet-content>
          <article className="tpnet-article">
            <article className="tpnet-article-inner">
              <div className="tpnet-article-body">
                <main>
                  <div className="content-wrapper">
                    <section className="product-header">
                      <div className="product-header__nacho" aria-hidden="true">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4466.99"
                          height="3883.9"
                          viewBox="0 0 4466.99 3883.9"
                          preserveAspectRatio="xMinYMid slice"
                        >
                          <path
                            id="product-header-nacho"
                            d="M4466.99 2450.12v49.92c-11.59 35-34.38
                                62.3-60.47 87.14-13 12.34-26.5 24.14-40.41 35.41-38.86 31.48-80.57
                                58.89-123.33 84.71-121.29 73.23-248.76 134.33-378 191.85-220.47 98.11-445.54
                                184.69-673 265.07-652.58 230.64-1315.75 425.1-1990.78 578.45-242.09
                                55-485.51 103.37-732.68 130.18-80.89 8.77-162 14.13-243.35
                                9.16-26.67-1.63-53.31-6.52-79.53-12-34-7.12-58.29-27.63-74-58.71-12.22-24.11-21.26-49.32-28.2-75.34-19.56-73.27-27.94-148.29-34-223.6-8-99.59-9.54-199.41-9.2-299.27.64-183.76
                                9.31-367.18 23.22-550.37 7.56-99.52 15-199.09 25-298.39 13.51-134.31
                                28.08-268.54 44.69-402.49 16.77-135.22 34.61-270.36 55.57-405 24.21-155.47
                                51-310.57 79.13-465.38 34.56-190.12 76.1-378.78 126.81-565.32 28.2-103.75
                                59.36-206.58 99.64-306.41 18.69-46.33 39.24-91.78 65.72-134.31 13.23-21.25
                                28-41.29 46.37-58.5 18-16.89 39.25-26.58 63.95-26.89a397.36 397.36 0 0 1
                                51.67 2.32c44.62 5.29 87.86 17.09 130.65 30.4 105.2 32.77 206.81 74.89
                                307.36 119.69 198.83 88.59 392.39 187.8 583.76 291.31 412.2 223 813.7 464
                                1207.35 718.1 287.16 185.39 569.54 377.74 842.71 583.35 144.24 108.57 285.66
                                220.63 419.54 341.92 67.87 61.49 133.79 125 192.61 195.35 42.31 50.61 82.33
                                102.8 101.2 167.65z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>

                      <header className="product-header__wrapper">
                        <div className="product-header__content">
                          <tpnet-heading class="light">
                            <h1>
                              <FormattedMessage id="LANDING.SUPPORT" />
                            </h1>
                          </tpnet-heading>
                          <tpnet-heading class="light">
                            <h2>
                              <FormattedMessage id="LANDING.PAGE_SUPPORT.TITLE" />
                            </h2>
                          </tpnet-heading>
                          <div className="product-header__ctas"></div>
                        </div>
                        <div
                          className="product-header__illo"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <img src={ImageSupport} alt="Support" />
                        </div>
                      </header>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="card-wrapper">
                      <div className="row">
                        <div className="col">
                          <div className="card">
                            <FaFileAlt />
                            <h3>
                              <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL1_TITLE" />
                            </h3>
                            <div className="description">
                              <p>
                                <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL1_DESC" />
                              </p>
                            </div>
                            <Link to="/documentation" className="cta-link">
                              <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL1_LINK" />
                            </Link>
                          </div>
                        </div>

                        <div className="col">
                          <div className="card">
                            <FaQuestionCircle />
                            <h3>
                              <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL2_TITLE" />
                            </h3>
                            <div className="description">
                              <p>
                                <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL2_DESC" />
                              </p>
                            </div>
                            <Link to="/faq" className="cta-link">
                              <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL2_LINK" />
                            </Link>
                          </div>
                        </div>

                        <div className="col">
                          <div className="card">
                            <FaCommentDots />
                            <h3>
                              <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL3_TITLE" />
                            </h3>
                            <div className="description">
                              <p>
                                <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL3_DESC" />
                              </p>
                            </div>
                            <Link to="/contact" className="cta-link">
                              <FormattedMessage id="LANDING.PAGE_SUPPORT.PANEL3_LINK" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <Herofooter />
                </main>
              </div>
            </article>
          </article>
        </tpnet-content>
      </div>
    </div>
  </Layout>
)

export default injectIntl(SupportPage)
